import { graphql } from "gatsby"

import CategoryTemplate from "../templates/category"

const ShopPage = CategoryTemplate

export const query = graphql`
  query Home {
    products: allContentfulProduct {
      edges {
        node {
          node_locale
          name
          subtitle
          slug
          order
          price
          photos {
            fluid(maxWidth: 300, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default ShopPage
